@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.main {
  min-height: calc(100vh - 50px);
  .header {
    background: map-get($colors, layout-grey-5);
    padding: 0.75rem 2rem;
    @include flex(space-between, center);
    .actions {
      display: flex;
      align-items: center;
      @include text(0.875rem, layout-grey-70);
      &_button {
        background: map-get($colors, layout-white);
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        box-shadow: 0px 1px 2px 1px rgba(52, 58, 64, 0.15);
        cursor: pointer;
        @include flex(space-between, center);
        @include text(0.875rem, layout-grey-70);
        margin: 0 0 0 1.5rem;
        &:hover {
          box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        }
        &:active {
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
        }
        &.actions_button__delete {
          @include text(0.875rem, message-negative-30, medium);
        }
        &.actions_button__warning {
          @include text(0.875rem, message-alert-30, medium);
        }
        &.actions_button__positive {
          @include text(0.875rem, message-positive-30, medium);
        }
        &.actions_button__info {
          @include text(0.875rem, darwined-blue-30, medium);
        }
        &:disabled {
          @include text(0.875rem, layout-grey-50, medium);
          background: transparent;
          box-shadow: none;
        }
      }
    }
    .buttons {
      display: flex;
      &_item {
        margin: 0 0 0 1.5rem;
        &:first-of-type {
          margin: 0;
        }
      }
    }
    .icon {
      margin: 0 0.5rem 0 0;
    }
  }
  .content {
    position: relative;
    background: map-get($colors, layout-white);
    overflow-x: hidden;
    max-width: 100vw;
    display: flex;
    @for $i from 100 through 200 {
      &.content__height#{$i} {
        $height: $i * 1px;
        min-height: calc(100vh - #{$height} - 50px);
      }
    }

    .aside {
      background: map-get($colors, foris-white);
      padding: 1.5rem 0 2rem;
      min-width: 240px;
      box-shadow: -1px -1px 0 4px 0 rgba(0, 0, 0, 0.25);
    }
    .formEdit {
      min-width: calc(100% - 240px);
      background: map-get($colors, layout-white);
      padding: 1.5rem 2rem 1.5rem 1.5rem;
      box-shadow: inset -1px 0 4px 0 rgba(0, 0, 0, 0.25);

      &_content {
        display: flex;
        flex-direction: column;

        .infoMessage {
          margin: map-get($spacing, sm) auto 0 0;
          min-width: 300px;
          max-width: 813px;
        }
      }

      .initCard {
        width: 100%;
      }
      .fields {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 1.5rem 0 0;
        &_content {
          flex: 3;
          display: block;
          min-width: 550px;
          max-width: calc((100 / 12) * 7%);
        }
      }
      .recommendationsContainer {
        flex: 1;
        max-width: calc((100 / 12) * 4.7%);
      }
      .forkCard {
        @include text(0.875rem, layout-grey-90);
        margin-top: 2rem;
        background: map-get($colors, layout-grey-5);

        &_title {
          font-weight: bold;
          margin-bottom: 0.25rem;
        }

        &_description {
          margin-bottom: 0.25rem;
        }

        &_button {
          margin-top: 1rem;
        }
      }
    }
    .footer {
      padding: 2rem 0;
      gap: 1rem;
    }
  }
}
